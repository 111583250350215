import { createContext, useCallback, useContext, useState } from 'react';

import { useToast } from 'hooks/toast';
import api from 'services';
import { Paginate } from 'hooks/nps';
import { format } from 'date-fns';
import { actions } from 'components/atoms/MovementHistory/FilterAction';

export interface IFilter {
  type?: '' | 'RECIPIENT' | 'DEPENDENT';
  action?: '' | 'I' | 'E' | 'T';
  search?: string;
  status?: string[];
  startDate?: Date;
  endDate?: Date;
  order_by?: 'action' | 'cnpj' | 'created_on' | 'id' | 'name' | 'type';
  order?: 'ASC' | 'DESC';
}

export interface MovementHistory {
  action: string;
  cnpj: string;
  created_on: Date;
  id: number;
  name: string;
  type: string;
  user_id: number;
  user_name: string;
}

export interface MovementHistories {
  content: MovementHistory[];
  error_message?: any;
  paginate: Paginate;
  success: boolean;
}

export interface PendingCorrectionMovement {
  id: number;
  beneficiary_name: string;
  type: string;
  status: string;
  validated_at: string;
}

export interface PendingCorrectionMovements {
  content: PendingCorrectionMovement[];
  error_message?: any;
  paginate: Paginate;
  success: boolean;
}

interface IContext {
  loading: boolean;
  filters: IFilter;
  movementHistories: MovementHistories;
  get: (filter?: IFilter, paginate?: Partial<Paginate>) => Promise<boolean>;
  applyFilter: (filter: IFilter) => boolean;
  clearFilter: () => void;
  pendingCorrectionMovements: PendingCorrectionMovements;
  listPendingCorrectionMovements: () => Promise<boolean>;
  changePage: (page: number) => void;
}

const toBooleanOwner = (owner: 'RECIPIENT' | 'DEPENDENT'): boolean => {
  if (owner === 'RECIPIENT') return true;
  return false;
};

const RecipientContext = createContext<IContext>({} as IContext);

const MovementHistoryProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [movementHistories, setMovementHistories] = useState<MovementHistories>({
    content: [] as MovementHistory[],
    paginate: {
      page: 0,
    },
  } as MovementHistories);
  const [pendingCorrectionMovements, setPendingCorrectionMovements] = useState<PendingCorrectionMovements>({
    content: [] as PendingCorrectionMovement[],
  } as PendingCorrectionMovements);

  const { addToast } = useToast();

  const get = useCallback(
    async (filtering?: IFilter, paginate?: Partial<Paginate>): Promise<boolean> => {
      try {
        setLoading(true);
        const response = (
          await api.movementHistory().get({
            start_date: filtering?.startDate ? format(filtering.startDate, 'yyyy-MM-dd') : undefined,
            end_date: filtering?.endDate ? format(filtering.endDate, 'yyyy-MM-dd') : undefined,
            order_by: filtering?.order_by,
            search: filtering?.search,
            order: filtering?.order,
            action: filtering?.action ? actions[filtering?.action] : undefined,
            page: paginate?.page,
            per_page: paginate?.per_page,
            owner: filtering?.type ? toBooleanOwner(filtering?.type) : undefined,
          })
        ).data as MovementHistories;

        if (response.paginate.page === 1) {
          setMovementHistories(response);
        } else {
          setMovementHistories(oldHistories => ({
            ...response,
            content: [...oldHistories.content, ...response.content],
          }));
        }

        setLoading(false);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível obter o histórico' });
        setLoading(false);
        return false;
      }
    },
    [addToast, setMovementHistories],
  );

  const applyFilter = useCallback(
    (filters: IFilter) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...filters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter(() => ({}));
  }, []);

  const changePage = useCallback(
    (page: number) => {
      setMovementHistories(oldHistories => ({
        ...oldHistories,
        paginate: { ...oldHistories.paginate, page },
      }));
    },
    [setMovementHistories],
  );

  const listPendingCorrectionMovements = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.movementHistory().listPendingCorrectionMovements({
        search: filter.search || undefined,
      });
      setPendingCorrectionMovements(response.data);
      return true;
    } catch (e) {
      addToast({ type: 'error', title: 'Não foi possível listar as correcções' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, filter]);

  return (
    <RecipientContext.Provider
      value={{
        loading,
        movementHistories,
        filters: filter,
        get,
        applyFilter,
        clearFilter,
        pendingCorrectionMovements,
        listPendingCorrectionMovements,
        changePage,
      }}
    >
      {children}
    </RecipientContext.Provider>
  );
};

function useMovementHistory(): IContext {
  const context = useContext(RecipientContext);
  if (!context) {
    throw new Error('useMovementHistory must be used within an MovementHistoryProvider');
  }
  return context;
}

export { MovementHistoryProvider, useMovementHistory };
