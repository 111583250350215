import { Fragment, lazy } from 'react';
import BriefcaseSolid from 'react-line-awesome-svg/icons/BriefcaseSolid';
import FileInvoiceDollarSolid from 'react-line-awesome-svg/icons/FileInvoiceDollarSolid';
import History from 'react-line-awesome-svg/icons/HistorySolid';
import MoneyBillWave from 'react-line-awesome-svg/icons/MoneyBillWaveSolid';
import NotesMedical from 'react-line-awesome-svg/icons/NotesMedicalSolid';
import Star from 'react-line-awesome-svg/icons/Star';
import StethoscopeSolid from 'react-line-awesome-svg/icons/StethoscopeSolid';
import TasksSolid from 'react-line-awesome-svg/icons/TasksSolid';
import UserCheck from 'react-line-awesome-svg/icons/UserCheckSolid';
import UsersSolid from 'react-line-awesome-svg/icons/UsersSolid';
import UserClock from 'react-line-awesome-svg/icons/UserClockSolid';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { MessageCircleQuestion, Text } from 'lucide-react';

import { Router, Route, Switch } from 'react-router-dom';

import { history } from 'App';

import AuthGuard from 'components/layout/Guard';

import RoutesEnum from 'enum/Routes';
import { GoogleAnalytics } from './analytics';

export interface IRoute {
  environment?: 'production' | 'development';
  icon?: any;
  description?: string;
  exact: boolean;
  path: string;
  component: any;
  permission?: string;
  showBadge?: boolean;
  analyticsKey?: string;
}

export const routesConfig = [
  {
    exact: true,
    path: RoutesEnum.LOGIN_ROUTE,
    component: lazy(() => import(/* webpackChunkName: "components/pages/Login" */ 'components/pages/Login')),
  },
  {
    path: RoutesEnum.FIRST_ACCESS_ROUTE,
    component: lazy(
      () => import(/* webpackChunkName: "components/pages/FirstAccess" */ 'components/pages/FirstAccess'),
    ),
  },
  {
    path: RoutesEnum.RECOVER_PASSWORD_ROUTE,
    component: lazy(
      () => import(/* webpackChunkName: "components/pages/ForgotPassword" */ 'components/pages/ForgotPassword'),
    ),
    exact: true,
  },
  {
    path: RoutesEnum.NEW_PASSWORD_ROUTE,
    component: lazy(
      () => import(/* webpackChunkName: "components/pages/ResetPassword" */ 'components/pages/ResetPassword'),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_PLAN_BENEFICIARY_FORM,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthPlanBeneficiaryForm" */ 'components/pages/HealthPlanBeneficiaryForm'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_DECLARATION_FINISH,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthDeclarationFinish" */ 'components/pages/HealthDeclarationFinish'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_DECLARATION_START,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthDeclarationStart" */ 'components/pages/HealthDeclarationStart'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_DECLARATION_LETTER_OF_ORIENTATION,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthDeclarationLetterOfOrientation" */ 'components/pages/HealthDeclarationLetterOfOrientation'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_DECLARATION_INSTRUCTIONS,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthDeclarationInstructions" */ 'components/pages/HealthDeclarationInstructions'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_DECLARATION_CORRECTION,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthDeclarationCorrection" */ 'components/pages/HealthDeclarationCorrection'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_DECLARATION_STEP_1,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthDeclarationSteps/step1" */ 'components/pages/HealthDeclarationSteps/step1'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_DECLARATION_STEP_2,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthDeclarationSteps/step2" */ 'components/pages/HealthDeclarationSteps/step2'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.HEALTH_PLAN_BENEFICIARY_FORM_DEPENDENT,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/HealthPlanBeneficiaryForm/onlyBeneficiary" */ 'components/pages/HealthPlanBeneficiaryForm/onlyBeneficiary'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.MOVEMENT_CORRECTION,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/MovementExcludeCorrection" */ 'components/pages/MovementExcludeCorrection'
        ),
    ),
  },
  {
    exact: true,
    path: RoutesEnum.MOVEMENT_TRANSFER,
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "components/pages/MovementCorrectionTransfer" */ 'components/pages/MovementCorrectionTransfer'
        ),
    ),
  },
  {
    guard: AuthGuard,
    layout: lazy(() => import(/* webpackChunkName: "components/layout/Main" */ 'components/layout/Main')),
    path: '/admin',
    type: 'admin',
    routes: [
      {
        icon: MoneyBillWave,
        description: 'Admin',
        exact: true,
        path: RoutesEnum.ADMIN,
        component: lazy(
          () => import(/* webpackChunkName: "components/pages/CompaniesPage" */ 'components/pages/CompaniesPage'),
        ),
        permission: 'admin.gestao.empresas',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_COMPANY_SETTINGS,
        component: lazy(
          () => import(/* webpackChunkName: "components/pages/CompaniesPage" */ 'components/pages/CompaniesAndUsers'),
        ),
        permission: 'admin.gestao.empresas',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_INTEGER_CONTRACTS,
        component: lazy(
          () => import(/* webpackChunkName: "components/pages/CompaniesPage" */ 'components/pages/IntegerContracts'),
        ),
        permission: 'admin.gestao.empresas.contratos',
      },
      {
        icon: BriefcaseSolid,
        description: 'Contratos PME',
        exact: true,
        path: RoutesEnum.ADMIN_CONTRACTS_PME,
        component: lazy(
          () => import(/* webpackChunkName: "components/pages/Contracts/Pme" */ 'components/pages/Contracts/Pme'),
        ),
        permission: 'admin.pme',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_CONTRACTS_PME_READJUSTMENT,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/Contracts/ReadjustmentPme" */ 'components/pages/Contracts/ReadjustmentPme'
            ),
        ),
        permission: 'admin.pme',
      },
      {
        icon: UserCheck,
        description: 'Avaliação de Cadastros',
        exact: true,
        path: RoutesEnum.ADMIN_BENEFICIARIES_ASSESSMENT,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/BeneficiariesAssessment" */ 'components/pages/BeneficiariesAssessment'
            ),
        ),
        permission: 'admin.movimentacao.avaliacao.cadastro',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_BENEFICIARIES_ASSESSMENT_VIEW,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/BeneficiariesAssessmentViewer" */ 'components/pages/BeneficiariesAssessmentViewer'
            ),
        ),
        permission: 'admin.movimentacao.avaliacao.cadastro',
      },
      {
        exact: true,
        icon: TasksSolid,
        description: 'Avaliação de Exclusão',
        path: RoutesEnum.ADMIN_BENEFICIARIES_EXCLUDE_VIEW,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/BeneficiariesExcludeList" */ 'components/pages/BeneficiariesExcludeList'
            ),
        ),
        permission: 'admin.movimentacao.avaliacao.exclusao',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_BENEFICIARIES_EXCLUDE,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/ExcludeBeneficiaryAssessment" */ 'components/pages/ExcludeBeneficiaryAssessment'
            ),
        ),
        permission: 'admin.movimentacao.avaliacao.exclusao',
      },
      {
        icon: Star,
        description: 'NPS',
        exact: true,
        path: RoutesEnum.ADMIN_NPS,
        component: lazy(() => import(/* webpackChunkName: "components/pages/Nps" */ 'components/pages/Nps')),
        permission: 'admin.nps',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_NPS_RATINGS,
        component: lazy(
          () => import(/* webpackChunkName: "components/pages/Nps/ratings" */ 'components/pages/Nps/ratings'),
        ),
        permission: 'admin.nps',
      },
      {
        exact: true,
        icon: StethoscopeSolid,
        description: 'Avaliação de Ds',
        path: RoutesEnum.ADMIN_HEALTH_DECLARATION_ASSESSMENT_VIEW,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthDeclarationAssessmentList" */ 'components/pages/HealthDeclarationAssessmentList'
            ),
        ),
        permission: 'admin.movimentacao.avaliacao.declaracao_saude',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_HEALTH_DECLARATION_ASSESSMENT,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthDeclarationAssessmentViewer" */ 'components/pages/HealthDeclarationAssessmentViewer'
            ),
        ),
        permission: 'admin.movimentacao.avaliacao.declaracao_saude',
      },
      {
        icon: UserCheck,
        exact: true,
        description: 'Avaliação de transferência',
        path: RoutesEnum.ADMIN_TRANSFER_EVALUATION,
        component: lazy(
          () =>
            import(/* webpackChunkName: "components/pages/TransferEvaluation" */ 'components/pages/TransferEvaluation'),
        ),
        showBadge: true,
        permission: 'admin.movimentacao.avaliacao.transferencia',
      },
      {
        exact: true,
        icon: StethoscopeSolid,
        description: 'Análise de Perícia',
        path: RoutesEnum.ADMIN_ANALYSIS,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthDeclarationAnalysis" */ 'components/pages/HealthDeclarationAnalysis'
            ),
        ),
        permission: 'admin.movimentacao.analise_pericia',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_ANALYSIS_VIEWER,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthDeclarationAssessmentViewer/analysis" */ 'components/pages/HealthDeclarationAssessmentViewer/analysis'
            ),
        ),
        permission: 'admin.movimentacao.analise_pericia',
      },
      {
        exact: true,
        icon: NotesMedical,
        description: 'Perícia',
        path: RoutesEnum.ADMIN_MEDICAL_EXPERTISE,
        component: lazy(
          () => import(/* webpackChunkName: "components/pages/MedicalExpertise" */ 'components/pages/MedicalExpertise'),
        ),
        permission: 'admin.movimentacao.pericia',
      },
      {
        exact: true,
        icon: NotesMedical,
        description: 'Sem Perícia',
        path: RoutesEnum.ADMIN_NO_MEDICAL_EXPERTISE,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/MedicalExpertise/medi" */ 'components/pages/NoMedicalExpertise'
            ),
        ),
        permission: 'admin.movimentacao.sem_pericia',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_MEDICAL_OPINION,
        component: lazy(
          () => import(/* webpackChunkName: "components/pages/MedicalOpinion" */ 'components/pages/MedicalOpinion'),
        ),
        permission: 'admin.movimentacao.sem_pericia',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_HEALTH_DECLARATION_VIEWER,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthDeclarationAssessmentViewer/view_only" */ 'components/pages/HealthDeclarationAssessmentViewer/view_only'
            ),
        ),
        permission: 'admin.movimentacao.declaracao_saude.visualizar',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_DETAILS_MOVEMENTS,
        component: lazy(() => import('components/pages/DetailsMovimentAdmin')),
        permission: 'admin.movimentacao.gestao',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_DETAILS_EXCLUDE_MOVEMENTS,
        component: lazy(() => import('components/pages/DetailsMovimentExcludeAdmin')),
        permission: 'admin.movimentacao.gestao',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_DETAILS_TRANSFER_MOVEMENTS,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/DetailsMovimentTransferAdmin" */ 'components/pages/DetailsMovimentTransferAdmin'
            ),
        ),
        permission: 'admin.movimentacao.gestao',
      },
      {
        icon: MoneyBillWave,
        description: 'Lançamento dos CIDs',
        exact: true,
        path: RoutesEnum.ADMIN_CIDS,
        component: lazy(() => import(/* webpackChunkName: "components/pages/Cids" */ 'components/pages/Cids')),
        permission: 'admin.movimentacao.lancamento_cid',
      },
      {
        exact: true,
        icon: StethoscopeSolid,
        path: RoutesEnum.ADMIN_CIDS_ADD,
        component: lazy(() => import(/* webpackChunkName: "components/pages/Cids/add" */ 'components/pages/Cids/add')),
        permission: 'admin.movimentacao.lancamento_cid',
      },
      {
        path: RoutesEnum.ADMIN_MEDICAL_EXPERTISE_ANALYSIS,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/MedicalExpertiseAnalysis" */ 'components/pages/MedicalExpertiseAnalysis'
            ),
        ),
        permission: 'admin.movimentacao.pericia',
      },
      {
        exact: true,
        path: RoutesEnum.ADMIN_ERROR_IMPORT,
        icon: HowToRegOutlinedIcon,
        description: 'Exportação SGU',
        permission: 'admin.gestao.exportacao',
        component: lazy(
          () =>
            import(/* webpackChunkName: "components/pages/MedicalExpertiseAnalysis" */ 'components/pages/ErrorImport'),
        ),
        showBadge: true,
        analyticsKey: 'sgu_errors',
      },
      {
        icon: InsertDriveFileIcon,
        exact: true,
        description: 'Importação de vidas em massa',
        path: RoutesEnum.ADMIN_HEALTH_IMPORT,
        component: lazy(() => import('components/pages/HealthImport')),
        showBadge: true,
        permission: 'admin.vidas.importacao',
      },
      {
        icon: History,
        description: 'Gestão de Movimentações',
        exact: true,
        path: RoutesEnum.ADMIN_TRACK_MOVEMENTS,
        component: lazy(() => import('components/pages/TrackMovimentAdmin')),
        permission: 'admin.movimentacao.gestao',
      },
      {
        description: 'Entrevista Qualificada',
        icon: MessageCircleQuestion,
        exact: true,
        path: RoutesEnum.ADMIN_QUALIFIED_INTERVIEW_LIST,
        component: lazy(() => import('components/pages/QualifiedInterviewList')),
        permission: 'admin.entrevista_qualificada',
      },
      {
        description: 'Vendas',
        exact: true,
        icon: Text,
        path: RoutesEnum.ADMIN_SALES,
        component: lazy(() => import('components/pages/FinishSalesList')),
        permission: 'admin.vendas',
      },
      {
        path: RoutesEnum.ADMIN_QUALIFIED_INTERVIEW_FILLING,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/QualifiedInterviewFilling" */ 'components/pages/QualifiedInterviewFilling'
            ),
        ),
        permission: 'admin.entrevista_qualificada',
      },
    ],
  },
  {
    guard: AuthGuard,
    layout: lazy(() => import(/* webpackChunkName: "components/layout/Main" */ 'components/layout/Main')),
    path: '/',
    type: 'user',
    routes: [
      // {
      //   icon: MoneyBillWave,
      //   description: 'Financeiro',
      //   exact: true,
      //   path: RoutesEnum.INDEX_ROUTE,
      //   component: lazy(() => import(/* webpackChunkName: "components/pages/Home" */ 'components/pages/Home')),
      //   permission: 'empresa.financeiro.fatura',
      // },
      {
        icon: MoneyBillWave,
        description: 'Financeiro',
        exact: true,
        path: RoutesEnum.FINANCE_ROUTE,
        component: lazy(() => import(/* webpackChunkName: "components/pages/Home" */ 'components/pages/NewHome')),
        permission: 'empresa.financeiro',
      },
      {
        icon: UsersSolid,
        description: 'Lista de Beneficiários',
        exact: true,
        path: RoutesEnum.HEALTH_PLAN_BENEFICIARY_ACTIVES,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthPlanBeneficiaryList/active" */ 'components/pages/HealthPlanBeneficiaryList/active'
            ),
        ),
        permission: 'empresa.beneficiarios',
      },
      {
        exact: true,
        path: RoutesEnum.HEALTH_PLAN_BENEFICIARY_NEW,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthPlanBeneficiaryNew" */ 'components/pages/HealthPlanBeneficiaryNew'
            ),
        ),
        permission: 'empresa.beneficiarios.incluir',
      },
      {
        exact: true,
        path: RoutesEnum.HEALTH_PLAN_BENEFICIARY_EDIT,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthPlanBeneficiaryEdit" */ 'components/pages/HealthPlanBeneficiaryEdit'
            ),
        ),
        permission: 'empresa.beneficiarios.editar',
      },
      {
        exact: true,
        path: RoutesEnum.HEALTH_PLAN_BENEFICIARY_TRANSFER,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/HealthPlanBeneficiaryTransfer" */ 'components/pages/HealthPlanBeneficiaryTransfer'
            ),
        ),
        permission: 'empresa.beneficiarios.transferir',
      },
      {
        exact: true,
        path: RoutesEnum.HEALTH_PLAN_BENEFICIARY_EXCLUDE,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/BeneficiaryExclusion" */ 'components/pages/BeneficiaryExclusion'
            ),
        ),
        permission: 'empresa.beneficiarios.excluir',
      },
      {
        icon: FileInvoiceDollarSolid,
        description: 'Comportamento financeiro',
        exact: true,
        path: RoutesEnum.FINANCIAL_BEHAVIOUR,
        component: lazy(
          () =>
            import(/* webpackChunkName: "components/pages/FinancialBehaviour" */ 'components/pages/FinancialBehaviour'),
        ),
        permission: 'empresa.sinistralidade',
      },
      {
        icon: History,
        description: 'Acompanhe as Movimentações',
        exact: true,
        path: RoutesEnum.TRACK_MOVEMENTS,
        component: lazy(() => import('components/pages/TrackMoviment')),
        permission: 'empresa.movimentacoes.acompanhar',
      },
      {
        icon: UserClock,
        exact: true,
        description: 'Correção de movimentações',
        path: RoutesEnum.LIST_MOVEMENTS_CORRECTIONS,
        component: lazy(
          () =>
            import(
              /* webpackChunkName: "components/pages/ListMovementsCorrections" */ 'components/pages/ListMovementsCorrections'
            ),
        ),
        permission: 'empresa.movimentacoes.corrigir',
        showBadge: true,
        analyticsKey: 'pending_correction',
      },
      {
        icon: FileInvoiceDollarSolid,
        exact: true,
        description: 'Reajustes',
        path: RoutesEnum.READJUSTMENTS,
        component: lazy(() => import('components/pages/Readjustments')),
        permission: 'empresa.financeiro.carta_reajuste',
      },
      {
        icon: FileInvoiceDollarSolid,
        description: 'Relatório de Valores Pagos',
        exact: true,
        path: RoutesEnum.INCOME_TAX_STATEMENT,
        component: lazy(() => import('components/pages/ImpostoRenda')),
        permission: 'empresa.financeiro.imposto_renda',
      },
    ],
  },
];

const renderRoutes: any = (routes: any) => {
  return routes ? (
    <Router history={history}>
      <GoogleAnalytics>
        <Switch>
          {routes.map((route: any, i: number) => {
            const Layout = route.layout || Fragment;
            const Guard = route.guard || Fragment;
            const Component = route.component;

            return (
              <Route
                key={i.toString()}
                path={route.path}
                exact={route.exact}
                render={props => (
                  <Guard routes={route.routes}>
                    <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      </GoogleAnalytics>
    </Router>
  ) : null;
};

const Routes: React.FC = () => {
  return renderRoutes(routesConfig);
};

export default Routes;
