import { AxiosInstance, AxiosResponse } from 'axios';

export class SecondCopy {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async secondCopyReasons(): Promise<AxiosResponse> {
    return this.instance.get('/api/second-copy/reasons');
  }

  async emitSecondCopy(body: any): Promise<AxiosResponse> {
    return this.instance.post('/api/admin/movements/second-copy', body);
  }
}
const secondCopy = (instance: AxiosInstance): SecondCopy => new SecondCopy(instance);

export default secondCopy;
