import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { track } from '@amplitude/analytics-browser';

import { format, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';

import { useToast } from 'hooks/toast';

import { IBeneficiaryNewData, IBeneficiaryUpdateData } from 'components/pages/HealthPlanBeneficiaryNew';

import api from 'services';
import { getStatusDescription } from 'utils/status';

export interface IFilter {
  type?: '' | 'RECIPIENT' | 'DEPENDENT';
  status?: string[];
  date?: Date;
  plan?: string;
  plan_description?: string;
  name?: string;
}

interface Document {
  id: number;
  file_id: string;
  name: string;
  path: string;
  relationship_id: string;
  relationship_type: string;
  type: string;
}
export interface Person {
  recipient: any;
  id: number | null;
  token: string | null;
  name: string;
  plan: string;
  plan_description?: string;
  cpf?: string;
  date_birth?: string;
  email?: string;
  genre?: string;
  gender?: string;
  kinship?: string;
  phone?: string;
  status: string;
  statusFormatted?: string;
  expires?: string;
  validity: string;
  validityFormatted?: string;
  type?: string;
  recipient_id?: string;
  address_city: string;
  address_complement: string;
  address_district: string;
  address_number: string;
  address_state: string;
  full_address: string;
  zipcode: string;
  cellphone: string | null;
  country: string | null;
  marital_status: string;
  mothers_name: string;
  social_gender?: string;
  social_name?: string;
  worker_registration?: string;
  codigo_beneficiario?: string;
  previous_beneficiary_code?: string;
  previous_ugf_plan?: boolean;
  previous_plan?: boolean;
  contract?: string;
}

interface Plan {
  cnpj: string;
  id: number;
  participation: string;
  plan_code: string;
  plan_description: string;
  type: string;
  contract_id: string;
}

interface Lookup {
  id?: number;
  description: string;
  value: string;
}

export interface Beneficiary extends Person {
  healthstatement?: boolean;
  dependents?: Person[];
  documents?: Document[];
  admission_date: string;
  user_id: number;
  validated: boolean;
  reply_date?: Date;
  movement_id?: number;
  portability?: boolean;
  previous_plan_code?: string;
  previous_beneficiary_code?: string;
  previous_ugf_plan?: boolean;
  previous_plan?: boolean;
  updated_on: Date;
  created_on: Date;
  deleted_on: Date;
}

interface ICreateDocumentParams {
  type: string;
  relationshipType: 'B' | 'D' | 'exclude_beneficiary' | 'sgu_code' | 'company_info';
  relationshipId: number | string;
  file: File;
}
interface IContext {
  loading: boolean;
  filters: IFilter;
  recipient: Beneficiary;
  recipients: Beneficiary[];
  plans: Plan[];
  workerTypes: Lookup[];
  listKinships: any[];
  genders: Lookup[];
  countries: Lookup[];
  maritalStatuses: Lookup[];
  socialGenders: Lookup[];
  kinshipDegrees: Lookup[];
  costCenters: Lookup[];
  createRecipient: (data: IBeneficiaryNewData, attachment: File) => Promise<boolean>;
  createDependent: (data: Partial<any>) => Promise<boolean>;
  updateRecipient: (data: Partial<IBeneficiaryUpdateData>) => Promise<boolean>;
  updateDependent: (data: IBeneficiaryUpdateData) => Promise<boolean>;
  createDocument: (data: ICreateDocumentParams) => Promise<string | undefined>;
  removeDocument: (documentId: string) => Promise<boolean>;
  getDocumentsByRecipient: (type: string, token: string) => Promise<Document[]>;
  getRecipients: (filter?: IFilter) => Promise<boolean>;
  confirmToken: (token: string) => Promise<boolean>;
  confirmTokenDependent: (token: string) => Promise<boolean>;
  deactivate: (token: string, type?: string) => Promise<{ healthstatement: boolean }>;
  getPlans: () => Promise<boolean>;
  getMinimumValidity: (contract_id: string) => Promise<any>;
  getMinimumValidityBySguCode: (sgu_code: string, contract_id: string) => Promise<any>;
  getWorkerTypes: (contract?: string) => Promise<boolean>;
  getGenders: () => Promise<boolean>;
  getCountries: () => Promise<boolean>;
  getMaritalStatuses: () => Promise<boolean>;
  getSocialGenders: () => Promise<boolean>;
  getKinshipDegrees: (contract?: string) => Promise<boolean>;
  getCostCenters: (contract?: string) => Promise<boolean>;
  getKinship: (contract?: string) => Promise<boolean>;
  getOne: (id: number, type?: 'recipients' | 'dependents') => Promise<boolean>;
  removeOne: (id: number, type?: 'recipients' | 'dependents') => Promise<boolean>;
  applyFilter: (filter: IFilter) => boolean;
  clearFilter: () => void;
}

const RecipientContext = createContext<IContext>({} as IContext);

const RecipientProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [recipients, setRecipients] = useState<Beneficiary[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [workerTypes, setWorkerTypes] = useState<Lookup[]>([]);
  const [genders, setGenders] = useState<Lookup[]>([]);
  const [countries, setCountries] = useState<Lookup[]>([]);
  const [maritalStatuses, setMaritalStatuses] = useState<Lookup[]>([]);
  const [costCenters, setCostCenters] = useState<Lookup[]>([]);
  const [socialGenders, setSocialGenders] = useState<Lookup[]>([]);
  const [kinshipDegrees, setKinshipDegrees] = useState<Lookup[]>([]);
  const [recipient, setRecipient] = useState<Beneficiary>({} as Beneficiary);
  const [listKinships, setListKinships] = useState<any[]>([]);

  const { addToast } = useToast();

  const createRecipient = useCallback(
    async (data: IBeneficiaryNewData, attachments: [File]) => {
      try {
        setLoading(true);

        const response = await api.recipient().create(data);

        const formData = new FormData();
        formData.append('type', 'employmentBlond');
        formData.append('relationship_type', 'B');
        formData.append('relationship_id', response.data.content.id);

        attachments.forEach(attachment => {
          formData.append('file', attachment);
        });

        await api.recipient().createDocument(formData);

        addToast({ type: 'success', title: 'Beneficiário criado!' });

        track('Titular Incluido');
        return true;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível criar um novo beneficiário' });
        }

        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const createDependent = useCallback(
    async (data: Partial<Person>) => {
      try {
        setLoading(true);

        await api.recipient().createDependent(data);

        track('Dependente Incluido');
        return true;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível criar um novo beneficiário' });
        }

        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const updateRecipient = useCallback(
    async (data: Partial<IBeneficiaryUpdateData>) => {
      try {
        setLoading(true);

        await api.recipient().update(data);

        addToast({ type: 'success', title: 'Beneficiário atualizado!' });

        return true;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível atualizar o beneficiário' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const updateDependent = useCallback(
    async (data: IBeneficiaryUpdateData) => {
      try {
        setLoading(true);

        await api.recipient().updateDependent(data);

        if (data.equalCep && data?.file?.residence?.fileId) {
          const documentId = data?.file?.residence?.fileId;
          const body = {
            relationship_id: String(data?.id),
            relationship_type: 'D',
            type: 'residence',
          };

          await api.recipient().duplicateDocument(documentId, body);
        }

        return true;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: `Não foi possível atualizar o dependente ${data.name}` });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getRecipients = useCallback(
    async filters => {
      try {
        setLoading(true);

        const response = await api.recipient().getAll(filters);

        const recipientsSplit: Beneficiary[] = [];

        response.data.content.forEach((recipientSplit: Beneficiary) => {
          const statusFormattedRecipient = getStatusDescription(recipientSplit.status);
          const validityFormattedRecipient = format(parseISO(recipientSplit.validity), 'dd/MM/yyyy');
          recipientsSplit.push({
            ...recipientSplit,
            type: recipientSplit.recipient_id ? 'Dependente' : 'Titular',
            statusFormatted: statusFormattedRecipient,
            status: recipientSplit.status,
            validityFormatted: validityFormattedRecipient,
            ...(filters?.type !== 'DEPENDENT'
              ? { recipient_id: recipientSplit.recipient_id }
              : {
                  recipient_id: undefined,
                }),
          });
        });

        setRecipients(recipientsSplit);

        return true;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível buscar os beneficiários' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getPlans = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.recipient().plans();

      setPlans(response.data.content);

      return true;
    } catch (error) {
      // eslint-disable-next-line
      // @ts-ignore
      const error_message = error?.response?.data?.error_message;

      if (error_message) {
        addToast({ type: 'error', title: error_message });
      } else {
        addToast({ type: 'error', title: 'Não foi possível buscar os planos' });
      }

      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, setPlans]);

  const getMinimumValidity = useCallback(
    async contract_id => {
      try {
        setLoading(true);

        const response = await api.recipient().minimumValidity(contract_id);

        return response;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar a validade mínima de vigência' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getMinimumValidityBySguCode = useCallback(async (sgu_code, contract_id) => {
    try {
      setLoading(true);

      const response = await api.recipient().minimumValidityBySguCode(sgu_code, contract_id);

      return response;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  const getWorkerTypes = useCallback(
    async (contract?: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().workerTypes(contract);

        setWorkerTypes(response.data.content);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar os tipos de colaborador' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast, setWorkerTypes],
  );

  const getGenders = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.recipient().genders();

      setGenders(response.data.content);

      return true;
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possível buscar os generos' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, setGenders]);

  const getSocialGenders = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.recipient().socialGenders();

      setSocialGenders(response.data.content);

      return true;
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possível buscar os generos' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, setSocialGenders]);

  const getMaritalStatuses = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.recipient().maritalStatuses();

      setMaritalStatuses(response.data.content);

      return true;
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possível buscar os estados civis' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, setMaritalStatuses]);

  const getCountries = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.recipient().countries();

      setCountries(response.data.content);

      return true;
    } catch (error) {
      addToast({ type: 'error', title: 'Não foi possível buscar os países' });
      return false;
    } finally {
      setLoading(false);
    }
  }, [addToast, setCountries]);

  const getKinshipDegrees = useCallback(
    async (contract?: string) => {
      try {
        setLoading(true);
        const response = await api.recipient().kinship(contract);
        setKinshipDegrees(response.data.content);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar os graus de parentesco' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast, setKinshipDegrees],
  );

  const getCostCenters = useCallback(
    async (contract?: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().costCenters(contract);

        setCostCenters(response.data.content);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar os centros de custo' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast, setCostCenters],
  );

  const getKinship = useCallback(
    async (contract?: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().kinship(contract);

        setListKinships(response.data.content);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar os graus de parentesco' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const confirmToken = useCallback(
    async (token: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().confirmToken(token);

        setRecipient(response.data.content);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const confirmTokenDependent = useCallback(
    async (token: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().confirmTokenDependent(token);

        setRecipient(rec => ({
          ...rec,
          dependents: [response.data.content],
        }));

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getOne = useCallback(
    async (id: number, type = 'recipients') => {
      try {
        setLoading(true);

        const response = await api.recipient().getOne(id, type);

        setRecipient(response.data.content);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const removeOne = useCallback(
    async (id: number, type = 'recipients') => {
      try {
        setLoading(true);

        await api.recipient().delete(id, type);

        addToast({ type: 'success', title: 'Beneficiário eliminado com sucesso' });

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível eliminar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const applyFilter = useCallback(
    (filters: IFilter) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...filters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearFilter = useCallback(() => {
    setFilter({} as IFilter);
  }, []);

  const createDocument = useCallback(
    async (data: ICreateDocumentParams) => {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append('type', data.type);
        formData.append('relationship_type', data.relationshipType);
        formData.append('relationship_id', data.relationshipId.toString());
        formData.append('file', data.file);

        const response = await api.recipient().createDocument(formData);

        return response.data.content.file_id;
      } catch (error) {
        // eslint-disable-next-line
        // @ts-ignore
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível salvar o documento.' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const deactivate = useCallback(
    async (token: string, type = 'beneficiary') => {
      try {
        setLoading(true);
        if (type === 'dependent') {
          const response = await api.recipient().deactivateDependent(token);
          return response.data.content;
        }

        const response = await api.recipient().deactivate(token);

        return response.data.content;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível desativar o token.' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const removeDocument = useCallback(
    async (documentId: string) => {
      try {
        setLoading(true);

        await api.recipient().removeDocument(documentId);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível remover um documento' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getDocumentsByRecipient = useCallback(
    async (type: string, token: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().getDocumentsByRecipient(token, type);

        return response.data.content;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível localizar documentos do beneficiário.' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (isEmpty(filter) === false) {
      getRecipients({
        ...filter,
        date: filter.date ? format(filter.date, 'yyyy-MM-dd') : undefined,
      });
    }
  }, [filter, getRecipients]);

  return (
    <RecipientContext.Provider
      value={{
        loading,
        recipients,
        recipient,
        plans,
        filters: filter,
        workerTypes,
        genders,
        countries,
        maritalStatuses,
        socialGenders,
        kinshipDegrees,
        costCenters,
        confirmToken,
        createRecipient,
        updateRecipient,
        updateDependent,
        getRecipients,
        applyFilter,
        clearFilter,
        createDocument,
        removeDocument,
        getDocumentsByRecipient,
        deactivate,
        getPlans,
        getMinimumValidity,
        confirmTokenDependent,
        getOne,
        removeOne,
        createDependent,
        getWorkerTypes,
        getGenders,
        getCountries,
        getMaritalStatuses,
        getSocialGenders,
        getKinshipDegrees,
        getCostCenters,
        getKinship,
        listKinships,
        getMinimumValidityBySguCode,
      }}
    >
      {children}
    </RecipientContext.Provider>
  );
};

function useRecipient(): IContext {
  const context = useContext(RecipientContext);
  if (!context) {
    throw new Error('useRecipient must be used within an RecipientProvider');
  }
  return context;
}

export { RecipientProvider, useRecipient };
