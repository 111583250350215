import { useCallback, useEffect, useMemo, useState } from 'react';

import pdfIcon from 'assets/imgs/pdf-icon.svg';

import { useDocuments } from 'hooks/documents';
import { useToast } from 'hooks/toast';
import { Container } from './styles';

interface Props {
  fileId?: string;
  file?: File;
  deleteFile: () => void;
  url?: string;
  name?: string;
  view?: boolean;
  download?: boolean;
  canDelete?: boolean;
  isHandleDownload?: boolean;
  nameFile?: string;
}

const PreviewUpload: React.FC<Props> = ({
  fileId,
  file,
  name = '',
  url,
  view,
  download,
  deleteFile,
  canDelete = false,
  isHandleDownload = false,
  nameFile = '',
}) => {
  const [src, setSrc] = useState('');
  const { downloadDocument } = useDocuments();
  const { addToast } = useToast();

  const handleDeleteFile = useCallback(() => {
    setSrc('');
    deleteFile();
  }, [deleteFile]);

  const handleOpenFile = useCallback(() => {
    window.open(url, '_blank');
  }, [url]);

  const DownloadFileData = useCallback(
    (data: any, file_id: any): any => {
      // eslint-disable-next-line no-shadow
      const url: any = window.URL.createObjectURL(new Blob([data]));
      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${nameFile}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    [nameFile],
  );

  const handleDownloadFile = useCallback(async () => {
    if (fileId) {
      try {
        const response: any = await downloadDocument(fileId);
        console.log('🚀 ~ file: index.tsx:60 ~ handleDownloadFile ~ response:', response);
        if (isHandleDownload) {
          DownloadFileData(response.data, fileId);
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao baixar o arquivo',
          description: 'Ocorreu um erro ao baixar o arquivo, tente novamente.',
        });
      }
    }
  }, [DownloadFileData, addToast, downloadDocument, fileId, isHandleDownload]);

  const filename = useMemo(() => {
    return file?.name || name;
  }, [file, name]);

  const isFilePdf = useMemo(() => {
    const split = name.split('.');
    const extension = split[split.length - 1];

    return extension === 'pdf' || file?.type === 'application/pdf';
  }, [file, name]);

  useEffect(() => {
    if (url) setSrc(url);
    if (file && file.type !== 'application/pdf') setSrc(URL.createObjectURL(file));
  }, [file, url]);
  return (
    <Container className="preview_upload__container">
      {isFilePdf ? (
        <div className="attachment__pdf">
          <img src={pdfIcon} alt="Document" />
        </div>
      ) : (
        src && <img src={src} alt="Document" className="attachment__image" />
      )}

      <div className="attachment__name">
        <span>{filename || 'Vazio'}</span>
        <div className="attachment__actions">
          {canDelete && (
            <button type="button" onClick={handleDeleteFile}>
              Deletar
            </button>
          )}
          {view && url && (
            <button type="button" onClick={handleOpenFile}>
              Visualizar
            </button>
          )}

          {download && fileId && (
            <button type="button" onClick={handleDownloadFile}>
              Download
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PreviewUpload;
