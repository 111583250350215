import { isEmpty } from 'lodash';

import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useToast } from 'hooks/toast';
import api from 'services';
import { PortabilityReviewStatuses } from 'hooks/portability/interfaces';
import { getStatusDescription } from 'utils/status';

export interface IFilter {
  type?: '' | 'RECIPIENT' | 'DEPENDENT';
  name?: string;
  page?: string;
}

export interface Document {
  id: number;
  file_id: string;
  name: string;
  path: string;
  relationship_id: string;
  relationship_type: string;
  type: string;
}

export interface Person {
  has_guardianship: boolean;
  id: number | null;
  token: string | null;
  name: string;
  plan: string;
  plan_description?: string;
  cpf?: string;
  date_birth?: string;
  age?: number;
  email?: string;
  genre?: string;
  gender?: string;
  kinship?: string;
  kinship_description?: string;
  phone?: string;
  status: string;
  statusFormatted?: string;
  expires?: string;
  validity: string;
  validityFormatted?: string;
  type?: string;
  recipient_id?: string;
  address_city: string;
  address_complement: string;
  address_district: string;
  address_number: string;
  address_state: string;
  full_address: string;
  zipcode: string;
  cellphone: string | null;
  country: string | null;
  marital_status: string;
  mothers_name: string;
  social_gender?: string;
  social_name?: string;
  worker_registration?: string;
  codigo_beneficiario?: string;
  healthstatement?: boolean;
  documents?: Document[];
  admission_date?: string;
  user_id?: number;
  user_email?: string;
  validated?: boolean;
  reply_date?: Date;
  movement_id?: number;
  portability?: boolean;
  previous_plan_code?: string;
  previous_beneficiary_code?: string;
  previous_ugf_plan?: boolean;
  previous_plan?: boolean;
  updated_on?: Date;
  created_on?: Date;
  deleted_on?: Date;
  dependents?: any[];
  grace_info?: any[];
  previous_cpt?: any[];
  has_previous_cpt?: boolean;
  portability_approved?: boolean;
  portability_review?: PortabilityReviewStatuses;
  plan_type?: string;
  comply_grace_period?: boolean;
  grace_info_review?: string;
  grace_info_approved?: boolean;
  take_previous_plan_grace_info?: boolean;
  grace_letter_status?: string;
  last_exclusion_date_status?: string;
  previous_plan_regulated_by_ans?: boolean;
  previous_plan_duration?: string;
  changed_accommodation?: boolean;
  previous_ugf_plan_info?: {
    contract: string;
    beneficiary_code: string;
    exclusion_date: string;
    is_plan_active: boolean;
    plan_description: string;
    plan_code: string;
    plan_validity: string;
    plan_type: string;
  };
  recipient?: any;
  relationship_type?: string;
  contract?: string;
  special_inclusion: string | null;
  special_inclusion_effective_date: string | null;
  approved_by_ocr?: boolean;
  need_ds?: boolean;
  previous_plan_operator?: string;
  sgu_code?: string;
}

export interface Beneficiary extends Person {
  healthstatement?: boolean;
  dependents?: Person[];
  recipient?: Person;
  documents?: Document[];
  admission_date: string;
  user_id: number;
  validated: boolean;
  reply_date?: Date;
  movement_id?: number;
  portability?: boolean;
  previous_plan_code?: string;
  previous_beneficiary_code?: string;
  previous_ugf_plan?: boolean;
  previous_plan?: boolean;
  updated_on: Date;
  created_on: Date;
  deleted_on: Date;
}

interface BeneficiaryKinshipDegree {
  code: string;
  description: string;
}

interface BeneficiaryPlanSGU {
  businessPlan: boolean;
  code: string;
  membershipPlan: boolean;
  title: string;
}

export interface BeneficiaryFromSGU {
  code: string;
  company: {
    name: string;
    cnpj: string;
  };
  sgu_code: string;
  contract: string;
  cpf: string;
  name: string;
  kinship_degree: BeneficiaryKinshipDegree;
  plan: BeneficiaryPlanSGU;
}

interface IContext {
  loading: boolean;
  beneficiary: Person;
  beneficiaryFromSGU: BeneficiaryFromSGU;
  beneficiaryKinshipDegree: BeneficiaryKinshipDegree[];
  getBeneficiary: (id: number, type?: 'recipients' | 'dependents') => Promise<boolean>;
  getBeneficiaryDocuments: (type: string, token: string) => Promise<Document[]>;
  getBeneficiaryDocumentsById: (type: string, beneficiaryId: string) => Promise<Document[]>;
  confirmBeneficiaryToken: (token: string, type?: 'recipients' | 'dependents') => Promise<boolean>;
  clearBeneficiary: () => void;
  filters: IFilter;
  beneficiaries: Beneficiary[];
  getBeneficiaries: (filter?: IFilter) => Promise<boolean>;
  getBeneficiaryFromSGU: (codigoBeneficiario: string) => Promise<boolean>;
  getBeneficiaryKinshipDegree: (beneficiaryContract: string) => Promise<boolean>;
  clearBeneficiaryFromSGU: () => void;
  totalBeneficiaries: number;
  qtyBeneficiariesPages: number;
  applyFilter: (filter: IFilter) => boolean;
}

const BeneficiaryContext = createContext<IContext>({} as IContext);

const BeneficiaryProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [beneficiary, setBeneficiary] = useState<Person>({} as Person);
  const [beneficiaryFromSGU, setBeneficiaryFromSGU] = useState<BeneficiaryFromSGU>({
    code: '',
    company: {
      name: '',
      cnpj: '',
    },
    contract: '',
    cpf: '',
    name: '',
    sgu_code: '',
    kinship_degree: {
      code: '',
      description: '',
    },
    plan: {
      businessPlan: false,
      code: '',
      membershipPlan: false,
      title: '',
    },
  });
  const [beneficiaryKinshipDegree, setBeneficiaryKinshipDegree] = useState<BeneficiaryKinshipDegree[]>([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);
  const [totalBeneficiaries, setTotalBeneficiaries] = useState<number>(0);
  const [qtyBeneficiariesPages, setQtyBeneficiariesPages] = useState<number>(1);

  const { addToast } = useToast();

  const getBeneficiary = useCallback(
    async (id: number, type = 'recipients') => {
      try {
        setLoading(true);

        const response = await api.recipient().getOne(id, type);

        setBeneficiary(response.data.content);

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const applyFilter = useCallback(
    (filters: IFilter) => {
      try {
        setLoading(true);
        setFilter(old => ({ ...old, ...filters }));
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível aplicar os filtros' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const calculateTotalPages = (total: number, perPage: number): number => {
    const totalPages = Math.floor(total / perPage);
    if (total % perPage === 0) return totalPages;
    return totalPages + 1;
  };

  const getBeneficiaries = useCallback(
    async filters => {
      try {
        setLoading(true);

        const response = await api.beneficiary().getAll(filters);

        const beneficiariesSplit: Beneficiary[] = [];

        response.data.content.forEach((beneficiarySplit: Beneficiary) => {
          const statusFormattedRecipient = getStatusDescription(beneficiarySplit.status);
          beneficiariesSplit.push({
            ...beneficiarySplit,
            statusFormatted: statusFormattedRecipient,
            ...(filters?.type !== 'DEPENDENT'
              ? { recipient_id: beneficiarySplit.recipient_id }
              : {
                  recipient_id: undefined,
                }),
          });
        });

        setBeneficiaries(beneficiariesSplit);
        const { total } = response.data.paginate;
        setTotalBeneficiaries(total);
        setQtyBeneficiariesPages(calculateTotalPages(total, response.data.paginate.per_page));

        return true;
      } catch (error) {
        const error_message = error?.response?.data?.error_message;

        if (error_message) {
          addToast({ type: 'error', title: error_message });
        } else {
          addToast({ type: 'error', title: 'Não foi possível buscar os beneficiários' });
        }
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (!isEmpty(filter)) {
      getBeneficiaries(filter);
    }
  }, [filter, getBeneficiaries]);

  const getBeneficiaryDocuments = useCallback(
    async (type: string, beneficiaryId: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().getDocumentsByRecipient(beneficiaryId, type);

        return response.data.content;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível localizar documentos do beneficiário.' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getBeneficiaryDocumentsById = useCallback(
    async (type: string, beneficiaryId: string) => {
      try {
        setLoading(true);

        const response = await api.recipient().getDocumentsByRecipientId(beneficiaryId, type);

        return response.data.content;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível localizar documentos do beneficiário.' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const getBeneficiaryFromSGU = useCallback(
    async (codigoBeneficiario: string) => {
      try {
        setLoading(true);
        const response = await api.beneficiary().getBeneficiaryFromSGU(codigoBeneficiario);
        setBeneficiaryFromSGU(response.data.content);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearBeneficiaryFromSGU = useCallback(() => {
    setBeneficiaryFromSGU({
      code: '',
      company_name: '',
      company_cnpj: '',
      contract: '',
      cpf: '',
      name: '',
      sgu_code: '',
      kinship_degree: {
        code: '',
        description: '',
      },
      plan: {
        businessPlan: false,
        code: '',
        membershipPlan: false,
        title: '',
      },
    } as any);
  }, []);

  const getBeneficiaryKinshipDegree = useCallback(
    async (beneficiaryContract: string) => {
      try {
        setLoading(true);
        const response = await api.beneficiary().getBeneficiaryKinshipDegree(beneficiaryContract);
        setBeneficiaryKinshipDegree(response.data.content);
        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const confirmBeneficiaryToken = useCallback(
    async (token: string, type = 'recipients') => {
      try {
        setLoading(true);

        if (type === 'dependents') {
          const response = await api.recipient().confirmTokenDependent(token);
          const beneficiaryInfo: any = response.data.content;
          setBeneficiary(beneficiaryInfo);
        }

        if (type === 'recipients') {
          const response: any = await api.recipient().confirmToken(token);
          setBeneficiary(response.data.content);
        }

        return true;
      } catch (error) {
        addToast({ type: 'error', title: 'Não foi possível buscar o beneficiário' });
        return false;
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const clearBeneficiary = useCallback(() => {
    setBeneficiary({} as Person);
  }, []);

  return (
    <BeneficiaryContext.Provider
      value={{
        beneficiary,
        beneficiaryFromSGU,
        beneficiaryKinshipDegree,
        loading,
        getBeneficiary,
        getBeneficiaryFromSGU,
        getBeneficiaryKinshipDegree,
        getBeneficiaryDocuments,
        getBeneficiaryDocumentsById,
        confirmBeneficiaryToken,
        clearBeneficiary,
        beneficiaries,
        filters: filter,
        getBeneficiaries,
        totalBeneficiaries,
        qtyBeneficiariesPages,
        applyFilter,
        clearBeneficiaryFromSGU,
      }}
    >
      {children}
    </BeneficiaryContext.Provider>
  );
};

function useBeneficiary(): IContext {
  const context = useContext(BeneficiaryContext);
  if (!context) {
    throw new Error('useBeneficiary must be used within an Beneficiary return context');
  }

  return context;
}

export { BeneficiaryProvider, useBeneficiary };
