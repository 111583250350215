import { AxiosInstance } from 'axios';

export class MovementHistory {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(params?: any): Promise<any> {
    return this.instance.get('/api/registrations', {
      params,
      handlerEnabled: false,
    });
  }

  listPendingCorrectionMovements(params?: any): Promise<any> {
    return this.instance.get('/api/movements/pending-correction', {
      params,
      handlerEnabled: false,
    });
  }
}

const movementHistory = (instance: AxiosInstance): MovementHistory => new MovementHistory(instance);

export default movementHistory;
