import { AxiosInstance } from 'axios';
import transferEvaluate, { TransferEvaluate } from './transferEvaluate/index';
import trackMoviments, { TrackMoviments } from './trackMoviments/index';
import financialBehaviour, { FinancialBehaviour } from './financialBehaviour/index';

import assessment, { Assessments } from './assessment';
import auth, { Auth } from './auth';
import analytics, { Analytics } from './analytics';
import billing, { Billing } from './billing';
import cid, { Cid } from './cids';
import company, { Company } from './company';
import config, { IConfigProps } from './config';
import contracts, { Contracts } from './contracts';
import documents, { Documents } from './documents';
import healthDeclaration, { HealthDeclaration } from './healthDeclaration';
import indicators, { Indicators } from './indicators';
import movementHistory, { MovementHistory } from './movementHistory';
import notifications, { Notifications } from './notifications';
import nps, { Nps } from './nps';
import reason, { Reason } from './reason';
import recipient, { Recipient } from './recipients';
import beneficiary, { Beneficiary } from './beneficiaries';
import beneficiariesExclude, { BeneficiariesExclude } from './beneficiariesExclude';
import users, { Users } from './users';
import portability, { Portability } from './portability';
import previous_cpt, { PreviousCPT } from './previous_cpt';
import grace_info, { GraceInfo } from './grace_info';

import imports from './imports';
import finantial, { Finantial } from './financial';
import transfer, { Transfer } from './transfer';
import detailsBeneficiary, { DetailsBeneficiary } from './detailsBeneficiary';
import healthStatement, { HealthStatement } from './health_statement';
import sales, { Sales } from './sales';
import secondCopy from './secondCopy';
import sguV2Api from './sgu';

export class UnimedAPI {
  instance: AxiosInstance;

  constructor(params: IConfigProps) {
    this.instance = config(params).instance();
  }

  assessment(): Assessments {
    return assessment(this.instance);
  }

  auth(): Auth {
    return auth(this.instance);
  }

  analytics(): Analytics {
    return analytics(this.instance);
  }

  users(): Users {
    return users(this.instance);
  }

  billing(): Billing {
    return billing(this.instance);
  }

  beneficiary(): Beneficiary {
    return beneficiary(this.instance);
  }

  transferEvaluate(): TransferEvaluate {
    return transferEvaluate(this.instance);
  }

  reason(): Reason {
    return reason(this.instance);
  }

  beneficiariesExclude(): BeneficiariesExclude {
    return beneficiariesExclude(this.instance);
  }

  recipient(): Recipient {
    return recipient(this.instance);
  }

  healthDeclaration(): HealthDeclaration {
    return healthDeclaration(this.instance);
  }

  sales(): Sales {
    return sales(this.instance);
  }

  healthStatement(): HealthStatement {
    return healthStatement(this.instance);
  }

  notifications(): Notifications {
    return notifications(this.instance);
  }

  nps(): Nps {
    return nps(this.instance);
  }

  contracts(): Contracts {
    return contracts(this.instance);
  }

  indicators(): Indicators {
    return indicators(this.instance);
  }

  company(): Company {
    return company(this.instance);
  }

  imports(): any {
    return imports(this.instance);
  }

  movementHistory(): MovementHistory {
    return movementHistory(this.instance);
  }

  documents(): Documents {
    return documents(this.instance);
  }

  cid(): Cid {
    return cid(this.instance);
  }

  portability(): Portability {
    return portability(this.instance);
  }

  previous_cpt(): PreviousCPT {
    return previous_cpt(this.instance);
  }

  grace_info(): GraceInfo {
    return grace_info(this.instance);
  }

  trackMoviments(): TrackMoviments {
    return trackMoviments(this.instance);
  }

  finantial(): Finantial {
    return finantial(this.instance);
  }

  transfer(): Transfer {
    return transfer(this.instance);
  }

  financialBehaviour(): FinancialBehaviour {
    return financialBehaviour(this.instance);
  }

  detailsBeneficiary(): DetailsBeneficiary {
    return detailsBeneficiary(this.instance);
  }

  secondCopy(): any {
    return secondCopy(this.instance);
  }

  sguV2Api(): any {
    return sguV2Api(this.instance);
  }
}

const Api = (params: IConfigProps): UnimedAPI => new UnimedAPI(params);

export default Api;
